.prepare {
    background-color: #fff;
    height: 100vh;
    overflow: hidden;
    padding-top: 72px;
    position: relative;

    .form {
        display: flex;
        height: 100%;
    }

    .navbar {
        align-items: center;
        background-color: #fff;
        border-bottom: 1px solid #D5DFE8;
        display: flex;
        height: 72px;
        justify-content: space-between;
        left: 0;
        padding-left: 20px;
        padding-right: 20px;
        position: fixed;
        top: 0;
        width: 100%;

        @media (min-width: 992px) {
            padding-left: 32px;
            padding-right: 32px;
        }

        > * {
            align-items: center;
            display: flex;
            flex-grow: 1;
            max-width: 30%;
            width: 1%;
        }

        .btn-back {
            .d-xl-flex {
                align-items: center;
            }

            text-align: left;

            @media (min-width: 1200px) {
                font-size: 16px;
            }
        }

        .nav {
            justify-content: flex-end;
        }
    }

    .aside {
        background-color: #fff;
        border-right: 1px solid #D5DFE8;
        display: flex;
        flex: 0 0 286px;
        flex-direction: column;
        font-size: 14px;
        // overflow: hidden;
        padding: 24px 20px;
        width: 286px;

        .form-label {
            color: #001122;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .form-group {
            border-bottom: 1px solid #E9EAEC;
            margin-bottom: 24px;
            padding-bottom: 24px;
        }

        .form-subgroup {
            + .form-subgroup {
                border-top: 1px solid #E9EAEC;
                margin-top: 10px;
                padding-top: 10px;
            }
        }

        .form-control {
            background-color: var(--action-bg-color);
            transition: background-color 0.15s ease;
        }

        .form-check {
            color: #384756;
            font-weight: 600;
            line-height: 1.5;
            padding: 8px 10px;

            .icon {
                fill: var(--action-color);
                margin-right: 10px;
            }

            &-input {
                display: none;
            }

            &:active,
            &:hover {
                background-color: var(--action-bg-color);
                border-radius: 4px;
            }
        }

        .group-fields {
            flex-grow: 1;
            margin-right: -20px;
            padding-right: 20px;
            overflow-y: auto;
        }
    }

    .timeline {
        align-items: center;
        color: #384756;
        display: flex;
        font-weight: 500;
        gap: 8px;
        justify-content: center;
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
        white-space: nowrap;

        @media (min-width: 992px) {
            gap: 16px;
        }

        li {
            align-items: center;
            display: flex;

            + li {
                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%231C2C3C' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M12.116 4.116a1.25 1.25 0 0 1 1.768 0l7 7a1.248 1.248 0 0 1 0 1.768l-7 7a1.25 1.25 0 0 1-1.768-1.768l4.866-4.866H4a1.25 1.25 0 1 1 0-2.5h12.982l-4.866-4.866a1.25 1.25 0 0 1 0-1.768Z' clip-rule='evenodd'/%3E%3C/svg%3E");
                    background-size: cover;
                    content: "";
                    display: block;
                    height: 16px;
                    margin-right: 8px;
                    width: 16px;

                    @media (min-width: 992px) {
                        margin-right: 16px;
                    }
                }
            }
        }

        .active {
            color: #1C2C3C;
            font-weight: 600;

            .timeline-icon {
                background-color: #1452FF;
                color: #fff;
            }
        }

        &-icon {
            background-color: #F5F7FB;
            border-radius: 50%;
            color: #8C97A0;
            display: inline-block;
            font-size: 0;
            height: 32px;
            line-height: 32px;
            text-align: center;
            width: 32px;

            @media (min-width: 992px) {
                margin-right: 8px;
            }

            .icon {
                vertical-align: middle;
            }
        }

        &-text {
            display: none;

            @media (min-width: 992px) {
                display: block;
            }
        }
    }

    .dashboard-content {
        padding-bottom: 48px;
        padding-top: 48px;
    }
}