.signer {
    &-header {
        align-items: flex-start;
        color: #54626F;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        height: 40px;
        justify-content: center;
        line-height: 1.35;
        padding-left: 56px;
        position: relative;

        .avatar {
            left: 0;
            position: absolute;
        }

        &:hover {
            color: currentColor;
        }
    }

    &-title {
        color: #1C2C3C;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        margin-bottom: 3px;
    }
}