.dropzone {
    $dropzone: &;

    display: flex;
    flex-direction: column;
    gap: 1rem;
    line-height: 1.35;
    user-select: none;
    width: 100%;

    @media (max-width: 991px) {
        justify-content: center;
    }

    .icon-dropzone {
        margin-bottom: 24px;
    }

    &.disabled {
        .dz-message {
            cursor: default;
        }
    }

    &-inline {
        height: 50px;
        width: 168px;

        .dz-message,
        .dz-preview {
            background-color: #fff;
            height: 100%;
            padding: 8px;
        }

        .dz-message {
            border-radius: 5px;
            border-style: solid;
            color: #8C97A0;
            flex-direction: row;
            font-size: 22px;
            font-weight: 500;
        }

        .dz-file-status {
            @media (min-width: 992px) {
                font-size: 12px;
                margin-top: 0;
            }
        }

        .icon-circle {
            align-items: center;
            background-color: #8C97A0;
            border-radius: 50%;
            display: flex;
            height: 20px;
            justify-content: center;
            position: relative;
            top: 2px;
            width: 20px;
        }

        .icon-check {
            color: #fff;
        }
    }

    &-header {
        margin-bottom: 26px;

        @media (min-width: 992px) {
            margin-bottom: 46px;
        }
    }

    &-opening {
        color: #54626F;

        @media (min-width: 992px) {
            font-size: 18px;
        }
    }

    &-actions {
        display: none;

        @media (min-width: 992px) {
            align-items: center;
            color: #707D88;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 40px;
            text-align: center;

            .icon {
                vertical-align: middle;
            }
    
            .or {
                display: block;
                text-transform: uppercase;
            }
    
            .or-file,
            .btn-file {
                align-items: center;
                display: flex;
                overflow: hidden;
            }
    
            .btn:not(.btn-primary) {
                .icon {
                    fill: #246BFF;
                }
            }
        }
    }

    &-lg {
        @media (max-width: 991px) {
            height: 100%;
            padding-top: 72px;
        }

        .dz-message {
            @media (max-width: 991px) {
                .btn + .btn {
                    margin-top: 16px;
                }
            }

            @media (min-width: 992px) {
                height: 516px;
                padding-left: 64px;
                padding-right: 64px;
            }
        }

        #{$dropzone}-actions {
            justify-content: center;
        }

        .or {
            @media (min-width: 992px) {
                margin-left: 2rem;
                margin-right: 2rem;
            }
        }

        .or-file,
        .btn-file {
            display: none;
        }
    }

    &:not(#{&}-inline) {
        margin-bottom: 20px;

        @media (min-width: 992px) {
            margin-bottom: 40px;
        }
    }

    &:not(.dropzone-lg) {
        .dz-message {
            > .btn-primary {
                @media (min-width: 992px) {
                    display: block;
                    opacity: 0;
                    transition: opacity 0.5s ease;
                }
            }
        }

        #{$dropzone} {
            &-header {
                display: none;

                @media (min-width: 992px) {
                    display: block;
                    margin-top: -210px;
                    opacity: 0;
                    transition: margin-top 0.75s ease, opacity 0.5s ease;
                }
            }

            &-actions {
                justify-content: center;

                @media (min-width: 992px) {
                    margin-bottom: 0;
                    transition: margin-bottom 1s ease;
                    width: 100%;
                }
            }
        }

        .or-file,
        .btn-file {
            @media (min-width: 992px) {
                display: block;
            }
        }
    }
}

.dz {
    $dz: &;

    &-message {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        @media (max-width: 991px) {
            .btn {
                width: 100%;
            }
        }

        @media (min-width: 992px) {
            background-color: #fff;
            border: 1px dashed #D5DFE8;
            border-radius: 4px;
            cursor: pointer;
            height: 96px;
            overflow: hidden;
            padding-left: 24px;
            padding-right: 24px;
            transition: height 0.75s ease;
        }
    }

    &-preview {
        align-items: center;
        border: 1px dashed #1452FF;
        border-radius: 4px;
        display: flex;
        font-size: 13px;
        font-weight: 600;
        justify-content: space-between;
        overflow: hidden;
        padding: 20px 10px;
        position: relative;
        transition: border-color 0.25s ease, opacity 0.25s ease;
        z-index: 0;

        @media (min-width: 992px) {
            font-size: 18px;
            padding: 28px 32px;
        }

        > .icon {
            color: #1452FF;
            flex: 0 0 auto;
            transition: color 0.25s ease;

            @media (min-width: 992px) {
                height: 48px;
                width: 48px;
            }
        }

        &-bg {
            height: 100%;
            object-fit: contain;
            object-position: left center;
            pointer-events: none;
            width: 100%;
        }
    }

    &-file {
        flex-grow: 1;
        margin-left: 10px;

        @media (min-width: 992px) {
            margin-left: 16px;
        }

        &-infos {
            color: #707D88;
            font-weight: normal;
            margin-top: 3px;
            text-transform: uppercase;

            @media (min-width: 992px) {
                font-size: 14px;
                line-height: 1.5;
            }
        }

        &-status {
            font-weight: 500;
            margin-top: 6px;

            @media (min-width: 992px) {
                font-size: 14px;
                margin-top: 12px;
            }
        }
    }

    &-progress {
        background-color: rgba(32, 114, 185, 0.16);
        height: 100%;
        left: 0;
        opacity: 1;
        position: absolute;
        top: 0;
        transition: width 0.5s ease, background-color 0.25s ease, opacity 0.25s ease;
        min-width: 1%;
        z-index: -1;
    }

    &-remove {
        padding-left: 0;
        padding-right: 0;
        text-transform: uppercase;
        white-space: nowrap;

        > .icon {
            color: #8C97A0;

            @media (max-width: 991px) {
                margin-right: 0 !important;
            }
        }
    }

    &-queue {
        opacity: 0.72;
    }

    &-success {
        background-color: #EAF6F1;
        border-color: #EAF6F1;
        opacity: 1;

        #{$dz}-file-status,
        > .icon {
            color: #00832D;
        }

        #{$dz}-progress {
            opacity: 0;
        }
    }

    &-error {
        background-color: #FFEEED !important;
        border-color: #FFEEED !important;
        opacity: 1;

        #{$dz}-file-status,
        > .icon {
            color: #EA4335;
        }

        #{$dz}-progress {
            opacity: 0;
        }
    }
}