button {
    border: none;
}

.btn {
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 5px;
    color: #1C2C3C;
    cursor: pointer;
    display: inline-flex;
    font-weight: 600;
    font-size: 14px;
    gap: 4px;
    justify-content: center;
    line-height: 24px;
    outline: none;
    padding: 11px 15px;
    text-align: center;
    transition: background-color 0.15s ease, border-color 0.15s ease, color 0.15s ease;
    user-select: none;

    .icon {
        fill: currentColor;
        min-height: 24px;
        vertical-align: top;
    }

    .spinner {
        height: 24px;
        width: 24px;

        circle {
            stroke: currentColor;
        }
    }

    &.disabled,
    &:disabled {
        cursor: not-allowed;
        pointer-events: none;
    }

    &.disabled {
        background-color: #F5F7FB;
        border-color: #D5DFE8;
        color: #A8B2B8;
    }

    &:hover {
        text-decoration: none;
    }

    &-block {
        width: 100%;
    }

    &-sm {
        padding-bottom: 7px;
        padding-top: 7px;
    }

    &-lg {
        font-size: 16px;
        padding-bottom: 15px;
        padding-top: 15px;
    }

    &-icon {
        border: none;
        padding: 0;
    }

    &-group {
        display: flex;
        flex-wrap: wrap;
        gap: 0.75rem;

        @media (min-width: 992px) {
            gap: 1rem;
        }
    }

    &-primary {
        background-color: #1452FF;
        color: #fff;

        &:hover {
            background-color: #246BFF;
            color: #fff;
        }

        &:active {
            background-color: #142573;
            color: #fff;
        }
    }

    &-secondary {
        background-color: #13935E;
        color: #fff;

        // @at-root .dropdown.show #{&},
        &:hover {
            background-color: #16A86B;
            color: #fff;
        }

        &:active {
            background-color: #107E50;
            color: #fff;
        }
    }

    &-danger {
        background-color: #EA4335;
        color: #fff;

        &:hover {
            background-color: #EA4335;
            color: #F9F9F9;
        }

        &:active {
            background-color: #EA4335;
            color: #fff;
        }
    }

    &-info {
        background-color: #fff;
        border-color: #D5DFE8;
        color: #246BFF;

        &:hover {
            background-color: #246BFF;
            border-color: #246BFF;
            color: #F9F9F9;
        }

        &:active {
            background-color: #142573;
            border-color: #142573;
            color: #fff;
        }
    }

    &-light {
        color: #1452FF;

        &:hover {
            background-color: #246BFF;
            color: #fff;
        }

        &:active {
            background-color: #142573;
            color: #fff;
        }

        &.disabled {
            background-color: transparent;
            border-color: transparent;
        }
    }

    &-link {
        color: #1452FF;
        padding-left: 0;
        padding-right: 0;

        &.disabled {
            border-color: transparent;
        }

        &:hover {
            color: #246BFF;
        }

        &:active {
            color: #142573;
        }
    }

    &-back {
        padding-left: 0;
        padding-right: 0;

        @media (min-width: 1200px) {
            background: none;
            color: #384756;
            font-weight: 500;

            &:hover {
                background: none;
                color: currentColor;
            }
        }
    }

    &-action {
        background-color: #fff;
        border: none;
        color: #8C97A0;

        &:hover,
        &:active {
            background-color: #D8E5FF;
        }
    }

    &-google {
        background-color: #fff;
        border-color: #D5DFE8;
        color: #142573;
    }

    &-remove {
        color: #A8B2B8;
        padding-bottom: 10px;
        padding-top: 10px;
        position: relative;

        .tooltip {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}