.table {
    color: #54626F;
    margin-bottom: 20px;
    vertical-align: top;
    width: 100%;

    @media (min-width: 992px) {   
        margin-bottom: 32px;
    }

    th {
        color: #1C2C3C;
        font-size: 12px;
        font-weight: 500;
        padding: 16px 8px;
        text-transform: uppercase;
    }

    td {
        font-size: 14px;
        height: 72px;
        padding: 8px;
        word-break: break-word;

        @media (min-width: 992px) {
            word-break: normal;
        }
    }

    .col-action {
        white-space: nowrap;
        width: 1%;
    }

    &-hover {
        > tbody {
            > tr {
                transition: background-color 0.15s ease;
    
                @media (min-width: 992px) {
                    &:hover {
                        background-color: #EBF1FF;
    
                        .btn-light {
                            &.has-hover {
                                background-color: #246BFF;
                                color: #fff;
                            }
                        }
    
                        .btn-action {
                            background-color: #D8E5FF;
                        }
                    }
                }
            }
        }
    }

    &-bordered {
        tr {
            @media (max-width: 991px) {
                &:first-child {
                    th, td {
                        border-top: 1px solid #D5DFE8;
                    }
                }
            }
        }

        th,
        td {
            border-bottom: 1px solid #D5DFE8;

            &:first-child {
                padding-left: 20px;
            }

            &:last-child {
                padding-right: 20px;
            }
        }
    }

    &-loading {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
    }

    &-actions {
        align-items: center;
        display: flex;
        justify-content: space-between;
        gap: 14px;

        .btn-group {
            flex-wrap: nowrap;
        }

        .dropdown-toggle {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &-responsive {
        // flex-grow: 1;
        // overflow-x: auto;
    
        @media (max-width: 991px) {
            margin-left: -20px;
            margin-right: -20px;

            thead {
                display: none;
            }
        }

        // @media (min-width: 992px) {
        //     overflow-x: visible;
        // }
    }
}

.table-form {
    line-height: 1.35;

    td {
        height: 48px;

        @media (min-width: 992px) {
            font-size: 16px;
            height: 73px;
            padding: 8px 20px;
        }
        
        &:first-child {
            font-weight: 500;
            white-space: nowrap;
            width: 1%;

            @media (min-width: 992px) {  
                color: #1C2C3C;
                padding-left: 0;
                padding-right: 65px;
            }
        }

        &:last-child {
            width: 100%;

            @media (min-width: 992px) {
                padding-right: 0;
            }
        }

        > .icon {
            vertical-align: middle;
        }
    }

    .form-group {
        margin-bottom: 0;

        @media (min-width: 576px) {        
            width: 262px;
        }
    }

    .icon-info {
        fill: #246BFF;
    }

    .d-flex {
        align-items: center;
        justify-content: space-between;

        @media (max-width: 991px) {
            flex-wrap: wrap;   
            gap: 0.25rem;
        }
    }
}